export default function ModeList() {
    return (
        <div>
            <h2>What mods can I play?</h2>
            <ul>
                <li>
                    <b>
                        <u>Slenderman:</u>
                    </b>{" "}
                    One player is chosen to be Slenderman. His goal is to kill
                    all the crewmates. Crewmates must avoid him and complete
                    their tasks. As he gets closer to a player, the player’s
                    vision will shrink. When Slenderman gets too close, he will
                    lunge and kill the player. If it starts getting dark, run!
                </li>
                <li>
                    <b>
                        <u>Zombies:</u>
                    </b>{" "}
                    One player is chosen to become a zombie. Zombies can infect
                    survivors by touching them, and win if they infect everyone.
                    As a survivor, your goal is to survive and finish all your
                    tasks.
                </li>
                <li>
                    <b>
                        <u>Cops &amp; Robbers:</u>
                    </b>{" "}
                    Cops must catch robbers before they can finish their tasks!
                    Robbers need to help each other avoid the cops, free their
                    captured teammates, and complete tasks. To free a captured
                    robber, run into a jail and touch them!
                </li>
                <li>
                    <b>
                        <u>Hot Potato:</u>
                    </b>{" "}
                    One person is selected to become the Potato, run away before
                    they tag you!
                </li>
                <li>
                    <b>
                        <u>Jester:</u>
                    </b>{" "}
                    One person is selected to become a Jester, they win if they
                    get themselves voted out!
                </li>
                <li>
                    <b>
                        <u>Troll:</u>
                    </b>{" "}
                    One person is selected to become a Troll, they win if they
                    get themselves killed by an imposter!
                </li>
                <li>
                    <b>
                        <u>Detective:</u>
                    </b>{" "}
                    One player is the secret detective and they will know who
                    murdered who in each meeting! Careful though as someone
                    might lie about being the detective to try and fool you
                </li>
                <li>
                    <b>
                        <u>50-50:</u>
                    </b>{" "}
                    The lobby is split into two sets of clones.
                </li>
                <li>
                    <b>
                        <u>CrazyColors:</u>
                    </b>{" "}
                    Every 15 seconds, everyone's color changes!
                </li>
                <li>
                    <b>
                        <u>Venteleporter:</u>
                    </b>{" "}
                    When you walk over a vent, you get teleported to a random
                    vent on the map!
                </li>
                <li>
                    <b>
                        <u>Twins:</u>
                    </b>{" "}
                    Everyone in the lobby has a twin, can they be trusted?
                </li>
                <li>
                    <b>
                        <u>Clones:</u>
                    </b>{" "}
                    Everyone has the same color, clothing, name, and pet. Who is
                    who?
                </li>
                <li>
                    <b>
                        <u>Escalation:</u>
                    </b>{" "}
                    Everyone's walk speed increases by 30% every time somebody
                    gets killed. Run around the map and try to catch the
                    impostor in the act!
                </li>
                <li>
                    <b>
                        <u>Standard:</u>
                    </b>{" "}
                    vanilla Among Us game. Nothing special!
                </li>
            </ul>
        </div>
    );
}
