import axios from "axios";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

function Int2Code(input: number): string {
    const V2 = "QWXRTYLPESDFGHUJKZOCVBINMA";
    var a = input & 0x3ff;
    var b = (input >> 10) & 0xfffff;

    return `${V2[a % 26]}${V2[Math.floor(a / 26)]}${V2[b % 26]}${
        V2[Math.floor((b / 26) % 26)]
    }${V2[Math.floor((b / (26 * 26)) % 26)]}${
        V2[Math.floor((b / (26 * 26 * 26)) % 26)]
    }`;
}

export default function LobbyBrowser() {
    const [tableBody, setTableBody] = useState<any[]>([]);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        axios.get("https://api.skeld.net/games").then((response: any) => {
            const newTableBody: any[] = [];
            const obj = response.data;
            obj.forEach((lobby: any) => {
                const obj = JSON.parse(lobby);
                if (obj == null) return;
                const code = Int2Code(obj.GameId);
                newTableBody.push(
                    <tr key={obj.GameId}>
                        <td>{obj.HostName}</td>
                        <td>{`${obj.PlayerCount}/${obj.MaxPlayers}`}</td>
                        <td>{code}</td>
                    </tr>
                );
            });
            setTableBody(newTableBody);
            setLoaded(true);
        });
    }, []);

    if (!loaded) {
        return <div>Loading the lobby browser...</div>;
    }

    return (
        <div>
            <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>Gamemode</th>
                        <th>Players</th>
                        <th>Code</th>
                    </tr>
                </thead>
                <tbody>{tableBody}</tbody>
            </Table>
        </div>
    );
}
