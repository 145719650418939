import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootswatch/dist/darkly/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import "./custom.scss";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDmAq5JhiV9xlWwVAvd1tGkzpmpmXZvZ7A",
    authDomain: "skeldnet-daacc.firebaseapp.com",
    projectId: "skeldnet-daacc",
    storageBucket: "skeldnet-daacc.appspot.com",
    messagingSenderId: "509548088737",
    appId: "1:509548088737:web:4b8640b6c420ba9b530fe0",
    measurementId: "G-ZWWLSSMY35",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
