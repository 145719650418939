import CustomButton from "../CustomButton";
import Install from "../Install";

export default function Landing() {
    return (
        <div>
            <div className="pb-4">
                <CustomButton to="/codes">Lobby Browser</CustomButton>
                <CustomButton to="https://discord.gg/B2Cj6usFBa">
                    Discord
                </CustomButton>
                <CustomButton to="https://www.patreon.com/skeld_net">
                    Patreon
                </CustomButton>
            </div>
            <h2>What is this?</h2>
            <article>
                <p>
                    Skeld.net is the world's ONLY free to play public modded
                    Among Us server. Ran by Arkatme and his amazing support
                    staff, Skeld.net offers you endless hours of extra enjoyment
                    if you have gotten bored of vanilla Among Us gameplay.
                </p>
            </article>
            <br />
            <Install />
        </div>
    );
}
