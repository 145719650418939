import { Button } from "react-bootstrap";

export default function CustomButton({ to, children }: any) {
    return (
        <Button
            className="m-2 p-3 fw-bold"
            variant="outline-foreground"
            size="lg"
            href={to}
        >
            {children}
        </Button>
    );
}
