import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";

export default function IOS() {
    const [deeplink, setDeeplink] = useState("");
    useEffect(() => {
        fetch("https://api.skeld.net/deeplinkios").then((response) => {
            response.text().then((text) => {
                setDeeplink(text);
            });
        });
    }, []);

    return (
        <div>
            <Card.Title>How to install skeld.net on iOS</Card.Title>
            <Card.Text style={{ fontSize: "1em" }}>
                <ol>
                    <li>Open Among Us to the main menu</li>
                    <li>Press this button</li>
                </ol>
                <Button variant="foreground" href={deeplink}>
                    Launch iOS
                </Button>
            </Card.Text>
        </div>
    );
}
