import { useState } from "react";
import { Card, Nav } from "react-bootstrap";
import Android from "./Android";
import IOS from "./IOS";
import PC from "./PC";
import PcWin11 from "./PcWin11";

export default function Install() {
    const [body, setBody] = useState(<Android />);
    return (
        <div>
            <h2>How to install</h2>
            <Card bg="light">
                <Card.Header>
                    <Nav variant="tabs" defaultActiveKey="#android">
                        <Nav.Item>
                            <Nav.Link
                                eventKey="#android"
                                className="text-background"
                                onClick={() => {
                                    setBody(<Android />);
                                }}
                            >
                                <h4>Android</h4>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="#ios"
                                className="text-background"
                                onClick={() => {
                                    setBody(<IOS />);
                                }}
                            >
                                <h4>iOS</h4>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="#pc"
                                className="text-background"
                                onClick={() => {
                                    setBody(<PC />);
                                }}
                            >
                                <h4>PC</h4>
                            </Nav.Link>
                        </Nav.Item>
                        {/*<Nav.Item>
                            <Nav.Link
                                eventKey="#pcwin11"
                                className="text-background"
                                onClick={() => {
                                    setBody(<PcWin11 />);
                                }}
                            >
                                <h4>PC (Win11)</h4>
                            </Nav.Link>
                            </Nav.Item>*/}
                    </Nav>
                </Card.Header>
                <Card.Body style={{color:"black"}}>{body}</Card.Body>
            </Card>
        </div>
    );
}
