import { Card } from "react-bootstrap";

export default function PC() {
    return (
        <div>
            <Card.Title>How to install skeld.net on PC</Card.Title>
            <Card.Text style={{ fontSize: "1em" }}>
                <ol>
                    <li>Make sure you have Among Us installed</li>
                    <li>Run Among Us, then close it</li>
                    <li>
                        <b>Run this command in command prompt:</b>
                        <br />
                        <mark>curl -k -o "%APPDATA%/../LocalLow/Innersloth/Among
                        Us/regionInfo.json" "https://api.skeld.net/regionInfo"</mark>
                        <br />
                        <b>If that does not work, try this command:</b>
                        <br />
                        <mark>Invoke-WebRequest -URI
                        "https://api.skeld.net/regionInfo" -Method Get -OutFile
                        "$env:APPDATA\..\LocalLow\Innersloth\Among
                        Us\regionInfo.json"</mark>
                    </li>
                    <li>Launch Among Us</li>
                    <li>Change your region to skeld.net</li>
                </ol>
            </Card.Text>
        </div>
    );
}
